import React from 'react';
import PostLayout from '../layouts/post';
import { Link } from 'gatsby';

export default function About({ data }) {
  return (
    <PostLayout>
      <h1>About me</h1>
      <p>
        I currently work as a software engineer at{' '}
        <a href="https://wealthfront.com/" rel="noopener noreferrer">
          Wealthfront
        </a>{' '}
        on web &amp; back-end projects. I <Link to="/">share my thoughts</Link>{' '}
        about the web platform, software engineering, Internet security &amp;
        privacy, Linux, hobbies, and general musings. In the past, I've been a:
      </p>
      <ul>
        <li>
          Software engineer at <a href="https://hellogrove.com/">Grove</a>, a
          financial planning startup (acquired by Wealthfront)
        </li>
        <li>
          Lead front-end engineer at <a href="https://dokkio.com/">Dokkio</a>, a
          tool to organize your files and folders
        </li>
        <li>
          Tech lead at{' '}
          <a href="https://socialhighrise.com/">Social High Rise</a>, an easy
          way for restaurant owners to manage social media
        </li>
        <li>
          Freelance web developer &amp; systems administrator for Fortune 500
          companies and small businesses
        </li>
      </ul>
      <p>You can email me to request my full resumé.</p>
      <p>
        In my free time, I like cooking, listening to music, learning Japanese,
        hiking, and tinkering with Linux.
      </p>

      <h2>Trivia</h2>
      <ul>
        <li>
          In 2017, I placed 3rd in a cybersecurity capture-the-flag event at the{' '}
          <a href="https://www.uscyberchallenge.org/">US Cyber Challenge</a>,
          earning a Lethal Forensicator challenge coin.
        </li>
        <li>
          In 2019, I placed 5th in the largest typing tournament, The Clicking
          Championship.
        </li>
        <li>
          I've founded several gaming communities, most notably I created an
          online community &amp; gaming platform for the largest StarCraft II
          modification from 2010-2012.
        </li>
      </ul>
    </PostLayout>
  );
}
