import React from 'react';

import styles from './author.module.css';

export default function Author() {
  return (
    <>
      <hr />
      <div className={styles.author}>
        <img src="/self.jpg" alt="Sidd's face" className={styles.image} />
        <div>
          <p>
            I write about UI engineering, JavaScript, and{' '}
            <a href="/about">other stuff</a>. I'm based out of San Francisco,
            CA, currently working on the web team at{' '}
            <a href="https://wealthfront.com/">Wealthfront</a>.
          </p>
          <p>
            Comments?{' '}
            <a href="https://twitter.com/siddsridharan">Tweet at me</a>, or{' '}
            <a href="mailto:sidd@sidd.com">shoot me an email</a>!
          </p>
        </div>
      </div>
    </>
  );
}
