import React from 'react';
import Footer from '../components/footer';
import Header from '../components/header';
import Author from '../components/author';

import styles from './post.module.css';

export default function PostLayout({ children, showAuthor }) {
  return (
    <div className={styles.post}>
      <Header />
      {children}
      {showAuthor && <Author />}
      <Footer />
    </div>
  );
}
